import React from "react";
import Navbar from "./components/navbar/Navbar";
import { BrowserRouter } from "react-router-dom";

import AnimatedRoutes from "./components/AnimatedRoutes";

import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import FloatingContactButtons from "./components/FloatingContactButtons";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <AnimatedRoutes />
      <Footer />
      <FloatingContactButtons />
      <ScrollToTop />
    </BrowserRouter>
  );
}

export default App;
