import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ContactUs.css";
import callIcon from "../../assets/contactus/call.svg";
import emailIcon from "../../assets/contactus/email.svg";
import locIcon from "../../assets/contactus/location_on.svg";
import { color, motion } from "framer-motion";
import { Clock, MapPin, Phone, Smartphone } from "lucide-react";
import { getContactInfo } from "../../utils/api";

function ContactUs() {
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    mobile: '',
    email: '',
    address: '',
    po_box: '',
    map_url: ''
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const data = await getContactInfo();
        setContactInfo(data);
      } catch (error) {
        console.error('Failed to fetch contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="contact-page">
        <div className="contact-hero">
          <Container>
            <motion.div
              className="hero-content"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <h1>Contact Us</h1>
              <p>We're here to help and answer any questions you might have</p>
              <p style={{ color: "white", fontSize: '16px', paddingTop: '10px', fontWeight: '700' }}>
                <Clock size={16} /> Sat - Thu: 07:30 AM - 12:30 PM
                <p style={{ color: "white", fontWeight: '700' }}>To</p>
                02:30 PM - 07:00 PM
              </p>
            </motion.div>
          </Container>
        </div>

        <div className="contact-section">
          <Container>
            <Row>
              <Col lg={4} md={6}>
                <motion.div
                  className="contact-card"
                  whileHover={{ y: -5 }}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.1 }}
                >
                  <div className="card-icon">
                    <img src={callIcon} alt="Phone" />
                  </div>
                  <h3>Call Us</h3>
                  <div className="card-content">
                    <Phone size={16} className="icon" />
                    <a href={`tel:${contactInfo.phone}`}>+{contactInfo.phone}</a>
                  </div>
                  <div className="card-content">
                    <Smartphone size={16} className="icon" />
                    <a href={`tel:${contactInfo.mobile}`}>+{contactInfo.mobile}</a>
                  </div>
                </motion.div>
              </Col>

              <Col lg={4} md={6}>
                <motion.div
                  className="contact-card"
                  whileHover={{ y: -5 }}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <div className="card-icon">
                    <img src={emailIcon} alt="Email" />
                  </div>
                  <h3>Email Us</h3>
                  <div className="card-content">
                    <a href={`mailto:${contactInfo.email}`}>
                      {contactInfo.email}
                    </a>
                    <p>24/7 online support</p>
                  </div>
                </motion.div>
              </Col>

              <Col lg={4} md={6}>
                <motion.div
                  className="contact-card"
                  whileHover={{ y: -5 }}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="card-icon">
                    <img src={locIcon} alt="Location" />
                  </div>
                  <h3>Visit Us</h3>
                  <div className="card-content">
                    <div className="office">
                      <h4>{contactInfo.address}</h4>
                      <p>{contactInfo.po_box}</p>
                    </div>
                  </div>
                </motion.div>
              </Col>
            </Row>

            <Row className="map-hours-section">
              <Col lg={12}>
                <motion.div
                  className="map-section"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <div className="map-container">
                    <h3 className="map-title">
                      <MapPin className="icon" size={20} /> Find Us Here
                    </h3>
                    <motion.div
                      className="map-wrapper"
                      whileHover={{ scale: 1.01 }}
                      transition={{ type: "spring", stiffness: 260, damping: 20 }}
                    >
                      <iframe
                        src={contactInfo.map_url}
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    </motion.div>
                  </div>
                </motion.div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </motion.div>
  );
}

export default ContactUs;
