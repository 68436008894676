import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Clock, Shield,Loader } from 'lucide-react';
import './ServiceDetail.css';
import { getServiceBySlug } from '../../utils/api';


const ServiceDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchService = async () => {
      try {
        setLoading(true);
        const data = await getServiceBySlug(slug);
        setService(data);
        setError(null);
      } catch (err) {
        setError('Failed to load service details');
        console.error('Error fetching service:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchService();
  }, [slug]);

  useEffect(() => {
    let intervalId;
    
    if (isAutoPlaying && service?.images?.length > 1) {
      intervalId = setInterval(() => {
        setCurrentImage((prev) => (prev === service.images.length - 1 ? 0 : prev + 1));
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAutoPlaying, service?.images]);

  const handleMouseEnter = () => setIsAutoPlaying(false);
  const handleMouseLeave = () => setIsAutoPlaying(true);

  if (loading) {
    return (
      <div className="loading-container" style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        backgroundColor: '#f4f4f4'
      }}>
        <Loader 
          size={50} 
          className="spinner" 
          style={{
            color: 'darkred', 
            animation: 'spin 1s linear infinite'
          }}
        />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={() => navigate('/services')}>Back to Services</button>
      </div>
    );
  }

  if (!service) return null;

  return (
    <div className="service-container">
      {/* Hero Section */}
      <section 
        className="service-hero" 
        style={{ 
          backgroundImage: `url(${service.images?.[0]?.image || service.bg_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="hero-overlay" />
        <div className="hero-content">
          <button 
            className="back-button"
            onClick={() => navigate(-1)}
            type="button"
          >
            <ArrowLeft size={20} />
            <span>Back to Services</span>
          </button>
          <h1 className="hero-title">{service.title}</h1>
          <p className="hero-description">{service.description}</p>
        </div>
      </section>

      <main className="service-content">
        <section className="service-overview">
          <div className="service-text-content">
            <h2 className="service-title-heading">{service.title}</h2>
            <div className="title-underline"></div>
          </div>
          <div className="overview-content">
            <div className="service-image">
              {service.images && service.images.length > 0 ? (
                <div 
                  className="image-slider"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {service.images.map((image, index) => (
                    <img 
                      key={index}
                      src={image.image} 
                      alt={`${service.title} #${index + 1}`}
                      className={`slider-image ${index === currentImage ? 'active' : ''}`}
                    />
                  ))}
                  <div className="slider-controls">
                    <button 
                      onClick={() => {
                        setCurrentImage((prev) => (prev === 0 ? service.images.length - 1 : prev - 1));
                        setIsAutoPlaying(false);
                      }}
                      className="slider-button prev"
                    >
                      ←
                    </button>
                    <button 
                      onClick={() => {
                        setCurrentImage((prev) => (prev === service.images.length - 1 ? 0 : prev + 1));
                        setIsAutoPlaying(false);
                      }}
                      className="slider-button next"
                    >
                      →
                    </button>
                  </div>
                  <div className="slider-dots">
                    {service.images.map((_, index) => (
                      <button
                        key={index}
                        className={`slider-dot ${index === currentImage ? 'active' : ''}`}
                        onClick={() => setCurrentImage(index)}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <img src={service.bg_image} alt={service.title} />
              )}
            </div>
            <div className="service-description">
              <p>{service.full_description}</p>
            </div>
          </div>
        </section>

        {/* Meta Section */}
        <section className="meta-section">
          <div className="meta-cards">
            <div className="meta-card">
              <div className="meta-card-icon">
                <Clock size={24} />
              </div>
              <div className="meta-card-content">
                <h4 className="meta-card-label">Timeline</h4>
                <span className="meta-card-value">{service.timeline}</span>
              </div>
            </div>
            <div className="meta-card">
              <div className="meta-card-icon">
                <Shield size={24} />
              </div>
              <div className="meta-card-content">
                <h4 className="meta-card-label">Guarantee</h4>
                <span className="meta-card-value">{service.guarantee}</span>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        {service.features && service.features.length > 0 && (
          <section className="features-section">
            <div className="features-content">
              <h2 className="section-title">Key Features</h2>
              <div className="features-grid">
                {service.features.map((feature, index) => (
                  <div 
                    className="feature-card" 
                    key={index}
                    style={{
                      backgroundImage: feature.image ? `url(${feature.image})` : 'none',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      position: 'relative',
                      height: '380px',
                      borderRadius: '0 40px 0 40px',
                      overflow: 'hidden',
                      border: '2px solid rgba(255, 255, 255, 0.2)',
                    }}
                  >
                    <div className="feature-overlay" style={{
                      background: 'linear-gradient(45deg, rgba(128, 0, 0, 0.9) 0%, rgba(128, 0, 0, 0.4) 100%)',
                      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 70%)'
                    }}></div>
                    
                    <div className="feature-index-container" 
                      style={{ 
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        zIndex: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                      }}
                    >
                      <div style={{
                        fontSize: '3rem',
                        fontWeight: '800',
                        color: 'white',
                        lineHeight: '1',
                        textShadow: '2px 2px 10px rgba(0,0,0,0.2)'
                      }}>
                        {(index + 1).toString().padStart(2, '0')}
                      </div>
                      <div style={{
                        width: '40px',
                        height: '4px',
                        backgroundColor: 'white',
                        marginTop: '5px'
                      }}></div>
                    </div>

                    <div className="feature-content" style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      right: '0',
                      padding: '30px',
                      background: 'white',
                      clipPath: 'polygon(0 30%, 100% 0, 100% 100%, 0 100%)',
                      minHeight: '180px',
                      display: 'flex',
                      alignItems: 'flex-end'
                    }}>
                      <h3 className="feature-title" style={{ 
                        color: 'rgb(128, 0, 0)',
                        fontSize: '1.5rem',
                        fontWeight: '700',
                        margin: '0',
                        maxWidth: '80%',
                        position: 'relative',
                        paddingTop: '20px'
                      }}>
                        {feature.text}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}

        {/* Benefits Section */}
        {service.benefits && service.benefits.length > 0 && (
          <section className="benefits-section">
            <div className="benefits-content">
              <h2 className="section-title">Benefits</h2>
              <div className="benefits-grid">
                {service.benefits.map((benefit, index) => (
                  <div className="benefit-card" key={index}>
                    <div className="benefit-number">{index + 1}</div>
                    <p className='benefit-text'>{benefit.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </main>
    </div>
  );
};

export default ServiceDetail;

