import React, { useState, useEffect } from 'react';
import './QuoteForm.css';
import { X } from 'lucide-react';
import { getServices } from '../../utils/api';
import { getContactInfo } from '../../utils/api';

function QuoteForm({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    services: [],
    preferredTime: ''
  });
  const [services, setServices] = useState([])
  const [contact, setContact] = useState({
    phone: '',
    mobile: '',
  })


  useEffect(() => {
    const fetchServices = async () => {
      const data = await getServices();
      setServices(data);
    };
    fetchServices();
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      const data = await getContactInfo();
      console.log(data,"asdfasdff")
      setContact(data);
    };
    fetchContact();
  }, []);
  // const services = [
  //   'Garage Door Installation',
  //   'Garage Door Repair',
  //   'Automatic Door Systems',
  //   'Maintenance Services',
  //   'Emergency Services'
  // ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare WhatsApp message
    const message = `*New Quote Request*\nName: ${formData.name}\nEmail: ${formData.email}\nMobile: ${formData.mobile}\nServices: ${formData.services.join(', ')}\nPreferred Time: ${formData.preferredTime}`;

    // Replace with your actual WhatsApp number
    // const whatsappNumber = '97412345678';
    const whatsappNumber = (contact?.mobile || contact?.phone)?.replace(/\s+/g, '');
    console.log(whatsappNumber)
    // Create WhatsApp URL with pre-filled message
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

    // Open WhatsApp in new tab
    window.open(whatsappUrl, '_blank');

    // Close modal and reset form
    onClose();
    setFormData({
      name: '',
      email: '',
      mobile: '',
      services: [],
      preferredTime: ''
    });
  };

  if (!isOpen) return null;

  return (
    <div className="quote-modal-overlay" style={{ zIndex:2000 }}>
      <div className="quote-modal">
        <button className="close-modal" onClick={onClose}>
          <X size={24} />
        </button>

        <h2>Request a Quote</h2>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              required
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="mobile">Mobile Number</label>
            <input
              type="tel"
              id="mobile"
              required
              value={formData.mobile}
              onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="service">Services Required</label>
            <select
              id="service"
              value=""
              onChange={(e) => {
                if (e.target.value) {
                  const newService = e.target.value;
                  if (!formData.services?.includes(newService)) {
                    setFormData({
                      ...formData,
                      services: [...(formData.services || []), newService]
                    });
                  }
                }
              }}
            >
              <option value="">Select a service</option>
              {services.map((service) => (
                <option key={service.id} value={service.title}>{service.title}</option>
              ))}
            </select>
            {formData.services && formData.services.length > 0 && (
              <div className="selected-services">
                <h4>Selected Services</h4>
                <div className="service-list">
                  {formData.services.map(service => (
                    <div key={service} className="service-item">
                      <span>{service}</span>
                      <button
                        type="button"
                        className="remove-btn"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            services: formData.services.filter(s => s !== service)
                          });
                        }}
                      >
                        ✕
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="time">Preferred Time</label>
            <input
              type="datetime-local"
              id="time"
              required
              value={formData.preferredTime}
              onChange={(e) => setFormData({ ...formData, preferredTime: e.target.value })}
            />
          </div>

          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default QuoteForm; 