import React,{useState,useEffect} from "react";
import logoWhite from "../../assets/logo/garagelogo.png";
import "./Footer.css";
import { Facebook, Instagram, Linkedin } from 'lucide-react';
import { Link } from "react-router-dom";
import { Phone, Mail, MapPin } from 'lucide-react';
import { FaXTwitter } from "react-icons/fa6";
import { getContactInfo } from "../../utils/api";

function Footer() {
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    mobile: '',
    email: '',
    address: '',
    po_box: '',
    map_url: ''
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const data = await getContactInfo();
        setContactInfo(data);
      } catch (error) {
        console.error('Failed to fetch contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo Section */}
        <div className="footer-logo-section">
          <img src={logoWhite} alt="Company Logo" />
          <div className="social-icons">
            <a href="https://www.instagram.com/activegarageqatar/" target="_blank" rel="noopener noreferrer" className="social-icon-link">
              <Instagram size={20} strokeWidth={2} />
            </a>
            <a  href="https://www.facebook.com/people/ActiveGarage-Qatr/pfbid026YYCtKwsN4XT8uU4DceiGGJuWTzZvtbLQisV6EqRkpu4zJ6BdvWrK61jTuVSJL9Hl/" target="_blank" rel="noopener noreferrer" className="social-icon-link">
              <Facebook size={20} strokeWidth={2} />
            </a>
            <a href="https://x.com/i/flow/login?redirect_after_login=%2FAct82013Garage" target="_blank" rel="noopener noreferrer" className="social-icon-link">
              <FaXTwitter size={20} strokeWidth={2} />
            </a>
            <a href="https://www.linkedin.com/in/active-garage-71a4aa293/" target="_blank" rel="noopener noreferrer" className="social-icon-link">
              <Linkedin  size={20} strokeWidth={2} />
            </a>
          </div>
        </div>

        {/* Quick Links */}
        <div className="footer-links-section hide-on-mobile">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/aboutus">About Us</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
          </ul>
        </div>


        {/* Contact Section */}
        <div className="footer-contact-section">
          <h3>Contact Us</h3>
          <div className="contact-info">
            <div className="contact-list">
              <div className="contact-item simple">
                <MapPin size={18} />
                <span>{contactInfo.address}</span>
              </div>
              
              <div className="contact-item simple">
                <Phone size={18} />
                <span>
                <a href={`tel:${contactInfo.phone}`}>+{contactInfo.phone}</a>
                </span>
              </div>
              <div className="contact-item simple">
                <Phone size={18} />
                <span>
                <a href={`tel:${contactInfo.mobile}`}>+{contactInfo.mobile}</a>
                </span>
              </div>

              <div className="contact-item simple">
                <Mail size={18} />
                <span><a href={`mailto:${contactInfo.email}`}>{contactInfo.email}
                </a></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Nasscript Software Innovations. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
