import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

import './Services.css';
import { getServices } from '../../utils/api';


const Services = () => {
  const [services, setServices] = useState([])

  useEffect(() => {
    const fetchServices = async () => {
      const data = await getServices();
      setServices(data);
    };
    fetchServices();
  }, []);


  return (
    <div className="services-main">
      <div className="services-hero">
        <motion.div
          className="hero-content"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1>Comprehensive Range of Services</h1>
          <p>Professional Auto Care Solutions</p>
        </motion.div>
      </div>

      <div className="services-container">
        {services.map((service, index) => {

          return (
            <motion.div
              key={index}
              className="service-card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${service.bg_image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="service-content">

                <h3>{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
              <Link to={`/services/${service.slug}`} className="learn-more">
                Learn More
                <ArrowRight size={16} className="arrow-icon" />
              </Link>
            </motion.div>
          );
        })}
      </div>

    </div>
  );
};

export default Services;