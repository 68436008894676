export const navItems = [
  // {
  //   id: 2,
  //   title: "PAGES",

  //   cName: "nav-item",
  // },
  {
    id: 1,
    title: "HOME",
    path: "/",
    cName: "nav-item",
  },
  {
    id: 3,
    title: "ABOUT US",
    path: "/aboutus",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "SERVICES",
    path: "/services",
    cName: "nav-item",
  },

  {
    id: 6,
    title: "CONTACT US",
    path: "/contactus",
    cName: "nav-item",
  },
];

export const servicesDropdown = [
  {
    id: 12,
    title: "About us",
    path: "/aboutus",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Team aapali",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "Why choose aapali",
    path: "/oilandgas",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Vission/Mission/Goals",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "FAQ",
    path: "/",
    cName: "submenu-item",
  },
];
