import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion, useAnimation } from "framer-motion";
import { fetchBlogs } from "../../utils/api";
import { useInView } from "react-intersection-observer";
import {
  FaCar,
  FaWrench,
  FaCogs,
  FaUserShield,
  FaAward,
  FaHandshake,
  FaChartLine,
  FaUsers,
  FaClock,
  FaShare,
  FaBookmark,
  FaArrowRight,
} from "react-icons/fa";
import "./AboutUs.css";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import QuoteForm from "../QuoteForm/QuoteForm";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { baseURLmedia } from "../../utils/api";
import { fetchFeatures } from "../../utils/api";
import team1 from "../../assets/Work Force/GRD_7750.jpg";
import team2 from "../../assets/Work Force/GRD_7763.jpg";
import team3 from "../../assets/Work Force/GRD_7804.jpg";
import team4 from "../../assets/Garage Team/GRD_8146.jpg";
import team5 from "../../assets/Garage Team/GRD_8139.jpg";
import team6 from "../../assets/Garage Team/GRD_8145.jpg";
import team7 from "../../assets/Garage Team/GRD_8151.jpg";

function AboutUs() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [features, setFeatures] = useState([]);

  // const features = [
  //   { icon: <FaCar />, title: "Premium Service", count: "5000+" },
  //   { icon: <FaWrench />, title: "Expert Mechanics", count: "40+" },
  //   { icon: <FaCogs />, title: "Modern Equipment", count: "100%" },
  //   { icon: <FaUserShield />, title: "Trusted Brand", count: "15+" },
  // ];

  const services = [
    {
      icon: <FaAward />,
      title: "Premium Diagnostics",
      description: "State-of-the-art diagnostic services",
    },
    {
      icon: <FaHandshake />,
      title: "Expert Repairs",
      description: "Professional repair services",
    },
    {
      icon: <FaChartLine />,
      title: "Performance Tuning",
      description: "Enhance your vehicle's performance",
    },
    {
      icon: <FaUsers />,
      title: "Customer Care",
      description: "24/7 dedicated support",
    },
  ];

  const controls = useAnimation();
  const [ref, inView] = useInView();

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    // Toggle body scroll
    if (isQuoteModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // Cleanup function to restore scroll when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isQuoteModalOpen]);

  const renderFeatures = () => {
    return (
      <Row className="features-row">
         {features.map((feature, index) => (
          <Col key={index} xs={6} sm={3} className="feature-col">
            <motion.div
              className="feature-card"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: isMobile ? 0.2 : index * 0.2 }}
            >
              {/* <div className="feature-icon">
                <i className={`fa${feature.icon}`}></i>
              </div> */}
              <h3 className="feature-count">{feature.count}</h3>
              <p className="feature-title">{feature.title}</p>
            </motion.div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderServices = () => {
    return (
      <Row className="services-row">
        {services.map((service, index) => (
          <Col
            key={index}
            xs={12}
            sm={6}
            md={3}
            className={`service-col ${isMobile ? "mobile-spacing" : ""}`}
          >
            <motion.div
              className="service-card"
              whileHover={!isMobile && !isTablet ? { y: -10 } : {}}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: isMobile ? 0.2 : index * 0.2 }}
            >
              <div className="service-icon" style={{ color: "rgb(128, 0, 0)" }}>
                {service.icon}
              </div>
              <h3 style={{ color: "rgb(128, 0, 0)" }}>{service.title}</h3>
              <p style={{ color: "rgb(128, 0, 0)" }}>{service.description}</p>
              <div className="button-wrapper">
                <Link to="/services" className="service-btn">
                  Learn More
                </Link>
              </div>
            </motion.div>
          </Col>
        ))}
      </Row>
    );
  };

  const handleGetStarted = () => {
    setIsQuoteModalOpen(true);
  };

  const handleLearnMore = () => {
    navigate("/contactus");
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  // Auto slide effect
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === blogPosts.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Changes slide every 3 seconds

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const loadBlogs = async () => {
      const blogs = await fetchBlogs();
      setBlogPosts(blogs.map(blog => ({ image: `${baseURLmedia}media/${blog.image}` })));
    };
  
    loadBlogs();
  }, []);

  useEffect(() => {
    const loadFeatures = async () => {
      const data = await fetchFeatures();
      setFeatures(data);
    };

    loadFeatures();
  }, []);
  

  return (
    <div className="about-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <motion.h1
            className="hero-title"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            {isMobile ? (
              <>
                ACTIVE
                <br />
                GARAGE
              </>
            ) : (
              "ACTIVE GARAGE"
            )}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
            className="hero-subtitle"
          >
            Elevating Automotive Excellence
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className="hero-buttons"
          >
            <button className="primary-btn" onClick={handleGetStarted}>
              Get Started
            </button>
            <button className="secondary-btn" onClick={handleLearnMore}>
              Learn More
            </button>
          </motion.div>
        </div>
        <div className="hero-overlays"></div>
      </section>

      {/* Add the QuoteForm component */}
      <QuoteForm
        isOpen={isQuoteModalOpen}
        onClose={() => setIsQuoteModalOpen(false)}
      />

      {/* Features Section */}
      <section className="features-section">
        <Container>{renderFeatures()}</Container>
      </section>

      {/* About Section */}
      <section className="about-section" ref={ref}>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <motion.div
                className="about-image-container"
                animate={controls}
                initial="hidden"
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: -100 },
                }}
              >
                <div className="about-image"></div>
                {/* <div className="experience-badge">
                  <span>40+</span>
                  <p>Years Experience</p>
                </div> */}
              </motion.div>
            </Col>
            <Col lg={6}>
              <motion.div
                className="about-content"
                animate={controls}
                initial="hidden"
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 100 },
                }}
              >
                <h2 className="section-title" style={{ paddingRight: '20px'}}>Excellence in Every Detail</h2>
                <p className="about-text">
                  Over five decades of experience has allowed Active Garage to
                  offer vehicle owners an exceptional level of quality in every
                  area of Automotive Service. Expertise... equipment...
                  facilities... Active Garage has all that it takes to undertake
                  any kind of repair, maintenance or service requirement, from
                  the very basic to the very complex.
                </p>
                <div className="about-features">
                  <div className="feature-item">
                    <div className="check-icon"></div>
                    <span>Certified Technicians</span>
                  </div>
                  <h3>Premium Equipment</h3>
                  <ul className="equipment-list">
                    <li className="feature-item">
                      <div className="check-icon"></div>
                      <span>
                        5000 sqm, 32-Bay facility at industrial area 51
                      </span>
                    </li>
                    <li className="feature-item">
                      <div className="check-icon"></div>
                      <span>State-of-the-art Equipment</span>
                    </li>
                    <li className="feature-item">
                      <div className="check-icon"></div>
                      <span>Trained, Experienced Technical Team</span>
                    </li>
                    <li className="feature-item">
                      <div className="check-icon"></div>
                      <span>Quality-control System & Process</span>
                    </li>
                  </ul>
                </div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* CTA Section */}
      <section className="cta-section">
        <Container>
          <div className="cta-content">
            <h2>Ready to Experience Premium Service?</h2>
            <p>
              Book your appointment today and join thousands of satisfied
              customers
            </p>
            <button className="cta-button" onClick={handleGetStarted}>Schedule Now</button>
          </div>
        </Container>
      </section>
      {/* Services Section */}
      <section className="services-section">
        <Container>
          <h2 className="section-title text-center">Our Services</h2>
          {renderServices()}
        </Container>
      </section>

      {/* Banner Section */}
      <section className="banner-section">
        <div className="banner-image">
          <Container>
            <div className="banner-content">
              <h2>Experience Excellence in Auto Care</h2>
              <p>
                Your trusted partner for professional automotive services,
                combining expertise with cutting-edge technology
              </p>
              <button className="banner-cta" onClick={handleGetStarted}>Schedule Service</button>
            </div>
          </Container>
        </div>
      </section>

      {/* Blog Section */}
      <section className="blog-section">
        <Container>
          <div className="blog-header text-center ">

            <h2 className="section-title mt-3">Our Blog</h2>
            <p className="section-subtitle">
              Stay updated with our automotive expertise
            </p>
          </div>

          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding"
          >
            {blogPosts.map((post, index) => (
              <div key={index} className="blog-card-wrapper">
                <div className="blog-card">
                  <div
                    className="card-image"
                    style={{ backgroundImage: `url(${post.image})` }}
                  >
                    <div className="card-overlay">
                      <div className="card-content">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </Container>
      </section>
    </div>
  );
}

export default AboutUs;
