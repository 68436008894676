import React from 'react';
import { motion } from 'framer-motion';
import { BsWhatsapp } from 'react-icons/bs';

const FloatingContactButtons = ({ isMobile }) => {
  return (
    <div className={`floating-contact-buttons ${isMobile ? "mobile" : ""}`}>
      <motion.a
        href="https://wa.me/+97477593231"
        target="_blank"
        rel="noopener noreferrer"
        className="contact-button whatsapp"
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          duration: 0.4,
          ease: "easeOut"
        }}
        style={{
          padding: "12px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <BsWhatsapp 
          size={68}
          style={{
            color: "#25D366"
          }}
        />
        <span className="tooltip" style={{
          position: "absolute",
          right: "100%",
          marginRight: "12px",
          backgroundColor: "#333",
          color: "white",
          padding: "6px 12px",
          borderRadius: "4px",
          fontSize: "14px",
          whiteSpace: "nowrap"
        }}>Chat on WhatsApp</span>
      </motion.a>
    </div>
  );
};

export default FloatingContactButtons; 