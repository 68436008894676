import React, { useState, useEffect } from "react";
import logo from "../../assets/logo/logoremovebg.png";
import logo2 from "../../assets/logo/Logo-second.png"
import "./NavbarStyles.css";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { navItems } from "./NavItems";

import {
  Phone,
  Mail,
  Instagram,
  Facebook,
  X,
  Clock,
  ArrowUpRight,
  Smartphone,
  Download,
  Linkedin

} from "lucide-react";
import { FaXTwitter } from "react-icons/fa6";
// import downarrow from "../../assets/icon/down.svg";
import QuoteForm from '../QuoteForm/QuoteForm';
import { BiMobile } from "react-icons/bi";
import { brochureApi, getContactInfo } from "../../utils/api";
import { baseURLmedia } from "../../utils/api";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [bgnav, setBgnav] = useState(false);
  const [brochureUrl, setBrochureUrl] = useState(null);
  const [error, setError] = useState(null); const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);
  const handleNav = () => setNav(!nav);
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    mobile: '',
    email: '',
    address: '',
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const data = await getContactInfo();
        setContactInfo(data);
      } catch (error) {
        console.error('Failed to fetch contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  useEffect(() => {
    const fetchBrochure = async () => {
      try {
        const data = await brochureApi.getBrochure();
        if (data.pdf_file) {
          const fullPdfUrl = `${baseURLmedia}${data.pdf_file}`;
          setBrochureUrl(fullPdfUrl);
        }
      } catch (error) {
        console.error('Failed to fetch brochure:', error);
      }
    };

    fetchBrochure();
  }, []);

  const handleDownload = () => {
    if (brochureUrl) {
      fetch(brochureUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'ActiveGarage_Brochure.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error('Download failed:', error);
        });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setBgnav(true);
      } else {
        setBgnav(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (nav) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [nav]);

  if (error) return <div>{error}</div>;

  return (
    <div className="header-wrapper">
      <div className="first-heading">
        <div className="desktop-contact-info">
          <div className="left-section">
            <div className="contact-items">
              <Mail size={16} />
              <span className="contact-text"> {contactInfo.email}</span>
            </div>
            <div className="contact-items">
              <Smartphone size={16} />
              <span className="contact-text">+{contactInfo.mobile}</span>
            </div>
            <div className="contact-items">
              <Phone size={16} />
              <span className="contact-text">+{contactInfo.phone}</span>
            </div>
          </div>
          <div className="right-section">
            <div className="contact-items">
              <Clock size={16} />
              <span className="contact-text">Sat - Thu: 07:30 AM - 07:00 PM</span>
            </div>
            <div className="social-icon">
              <a href="https://www.instagram.com/activegarageqatar/" target="_blank">
                <Instagram size={16} />
              </a>
              <a href="https://www.facebook.com/people/ActiveGarage-Qatr/pfbid026YYCtKwsN4XT8uU4DceiGGJuWTzZvtbLQisV6EqRkpu4zJ6BdvWrK61jTuVSJL9Hl/" target="_blank">
                <Facebook size={16} />
              </a>
              <a href="https://x.com/i/flow/login?redirect_after_login=%2FAct82013Garage" target="_blank">
                <FaXTwitter size={16} />
              </a>
              <a href="https://www.linkedin.com/in/active-garage-71a4aa293/" target="_blank">
                <Linkedin size={20} strokeWidth={2} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={bgnav ? "nav-header active" : "nav-header"}>
        <div className="nav-container">
          <div className="nav-logo-section">
            <Link to="/" className="logo-link">
              <div className={bgnav ? "logo-container-scrolled" : "logo-container"}>
                <div className="logo-brand-wrapper">
                  <div className="logo-box">
                    <img src={logo} alt="Company logo gulf shore" />
                    <div className="accent-line"></div>
                  </div>
                  <div className="brand-content">
                    <div className="accent-line2"></div>

                    <div className="brand-name">
                      <span className="brand-first">Active</span>
                      <span className="brand-second">Garage</span>
                    </div>

                    <div className="brand-slogan">
                      <span>EXCELLENCE IN AUTOMOBILE</span>
                    </div>
                  </div>
                </div>

              </div>

            </Link>
            <div className="brand2-content">
              Part of <br />
              QATAR MASTERS GROUP
            </div>
          </div>

          <div className="nav-menu-section">
            <ul className="nav-menu">
              {navItems.map((item) => (
                <li key={item.id} className={item.cName}>
                  <Link style={{ color: "rgb(128, 0, 0)" }} to={item.path}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="nav-contact-section">

            <div className="quote-btn">
              <button
                style={{ color: 'white' }}
                className="quote-link"
                onClick={handleDownload}
                disabled={!brochureUrl}
              >
                OUR BROCHURE
                <Download size={16} />

              </button>
            </div>

            <div className="quote-btn">
              <button
                className="quote-link"
                onClick={() => setIsQuoteFormOpen(true)}
              >
                GET QUOTE
                <ArrowUpRight className="arrow-icon" size={16} />
              </button>
            </div>
          </div>

          <div className="hamburger" onClick={handleNav}>
            <HiOutlineMenuAlt4 color="rgb(128, 0, 0)" size={40} />
          </div>
        </div>

        <div className={nav ? "mobile-menu active" : "mobile-menu"}>
          <div className="menu-container">
            <div className="menu-header">
              <button className="close-btn" onClick={handleNav}>
                <X size={24} strokeWidth={2.5} color="rgb(128, 0, 0)" />
              </button>
            </div>

            <nav className="menu-nav">
              <Link
                to="/"
                className="menu-link"
                onClick={handleNav}
              >
                Home
              </Link>

              <Link
                to="/aboutus"
                className="menu-link"
                onClick={handleNav}
              >
                About
              </Link>

              <Link
                to="/services"
                className="menu-link"
                onClick={handleNav}
              >
                Services
              </Link>

              <Link
                to="/contactus"
                className="menu-link"
                onClick={handleNav}
              >
                Contact
              </Link>
              <div className="mobile-quote-btn">
                <button
                  style={{ color: 'black' }}
                  className="quote-link"
                  onClick={handleDownload}
                  disabled={!brochureUrl}
                >
                  OUR BROCHURE
                </button>
              </div>

              <div className="mobile-quote-btn">
                <button
                  className="quote-link"
                  onClick={() => setIsQuoteFormOpen(true)}
                >
                  GET QUOTE
                  <ArrowUpRight className="arrow-icon" size={16} />
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <QuoteForm
        isOpen={isQuoteFormOpen}
        onClose={() => setIsQuoteFormOpen(false)}
      />
    </div>
  );
}

export default Navbar;
