import axios from 'axios';

// export const baseURLmedia = 'http://127.0.0.1:8000/'
export const baseURLmedia = 'https://admin.activegarageqatar.com/'


// export const api = axios.create({
//     baseURL: 'http://127.0.0.1:8000/api' 
// });

export const api = axios.create({
  baseURL: 'https://admin.activegarageqatar.com/api' 
});

export const getServices = async () => {
    try {
        const response = await api.get('/services/');
        return response.data;
    } catch (error) {
        console.error('Error fetching services:', error);
        throw error;
    }
};

export const getServiceBySlug = async (slug) => {
    try {
        const response = await api.get(`/services/${slug}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching service details:', error);
        throw error;
    }
};

export const fetchBlogs = async () => {
    try {
      const response = await api.get('/blogs/');
      return response.data;
    } catch (error) {
      console.error('Error fetching blogs:', error);
      return [];
    }
  };


export  const fetchFeatures = async () => {
    try {
      const response = await api.get("/features/");
      return response.data;
    } catch (error) {
      console.error("Error fetching features:", error);
      return [];
    }
  };

  export const getContactInfo = async () => {
    try {
      const response = await api.get("/contact-info/");
      return response.data;
    } catch (error) {
      console.error('Error fetching contact info:', error);
      throw error;
    }
  };

  export const brochureApi = {
    getBrochure: async () => {
        try {
            const response = await api.get('/brochure/');
            return response.data;
        } catch (error) {
            console.error('Error fetching brochure:', error);
            throw error;
        }
    }
};
  